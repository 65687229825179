import React from "react"
import Layout from "../components/layout"

function Input({ id, label, type, ...rest }) {
  return (
    <div className="mb-6">
      <label htmlFor={ id } className="block mb-2 text-sm text-gray-600 dark:text-gray-400">{ label }</label>
      <input type={ type } name={ id } id={ id } placeholder={ label } { ...rest } required className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-bubble-blue-100 focus:border-bubble-blue" />
    </div>
  ) 
}

export default function Contact({data}) {

  return (
    <Layout>
      <div className="prose px-6 md:px-0 pt-6 md:pt-12 md:mx-auto">
        <h1 className="my-3 text-3xl font-semibold text-gray-700">Kontakt oss</h1>
        <p className="text-gray-400">
          Navn/mail/telefon/adresse
          Facebook, Instagram - Linked In
        </p>
        <form action="https://api.web3forms.com/submit" method="POST" id="form">

          <input type="hidden" name="apikey" value={`${process.env.GATSBY_WEB3FORMS_ACCESS_KEY}`} />
          <input type="hidden" name="subject" value="Leads fra turbobla.no" />
          <input type="checkbox" name="botcheck" style={{ display: `none` }} />
          <input type="hidden" name="redirect" value={`${process.env.GATSBY_WEB3FORMS_RECEIPT_URL}`} />

          <Input id="name" label="Fullt navn" type="text" />
          <Input id="email" label="E-post" type="text" />
          <Input id="phone" label="Telefon" type="text" />

          <fieldset className="border p-2 mb-6">
            <legend>Tur</legend>
            <Input id="destination" label="Destinasjon" type="text" />
            <Input id="experience" label="Opplevelse" type="text" />
            <Input id="date" label="Dato (ca)" type="text" />
          </fieldset>

          <div className="mb-6">
            <button type="submit" className="px-3 py-4 text-white bg-bubble-orange rounded-md focus:outline-none">Send forespørsel</button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
